<template>
  <form @submit.prevent="resetearClave" class="perfil_cambiar_clabe">
    <p class="clave__description">{{ $t('perfil.txt_si_requieres_modificar' )}}</p>
    <div class="clave__password">
      <PasswordComponent
          :placeholder="$t('placeholder.txt_contrasena_actual')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="old_password"
      >
      </PasswordComponent>
      <PasswordComponent
          :placeholder="$t('placeholder.txt_nueva_contrasena')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="new_password"
      >
      </PasswordComponent>
      <PasswordComponent
          :placeholder="$t('placeholder.txt_confirmar_nueva_contrasena')"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="new_password"
      >
      </PasswordComponent>
      <div class="clave__buttons">
        <ButtonPrimary
            type="submit"
            :loading="loading"
        >
          {{ $t('buttons.txt_confirmar_contrasena') }}
        </ButtonPrimary>
      </div>
    </div>
    <ButtonSecundary
        type="button"
        @click="sendEmailRecover"
        :loading="loading"
    >
      {{ $t('buttons.txt_recuperar_contrasena_por_correo') }}
    </ButtonSecundary>
  </form>
</template>

<script>
import PasswordComponent from "@/components/new/inputs/PasswordComponent.vue";
import User from "@/services/user-services";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";
import {mapState} from "vuex";

export default {
  name: "PerfilCambiarClave",
  components: {
    ButtonPrimary,
    PasswordComponent,
    ButtonSecundary
},
  data () {
    return {
      old_password: '',
      new_password: '',
      loading: false,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {

    async sendEmailRecover() {
      this.loading=true
      let response = await User.sendMailPassword({'email': this.user.email})
      console.log(response.data)
      if (response.data.success) {
        this.$toast.success(this.$t('perfilcambiarclave.txt_correo_para_cambio_de_contrasena'))
        this.loading=false
      } else {
        this.$toast.error(response.data.message)
        this.loading=false
      }
    },
    async resetearClave() {
      if (this.new_password.length < 6) {
        this.$toast.error(this.$t('perfilcambiarclave.txt_la_contrasena_debe_tener'))
        return
      }
      this.loadingCambiar = true
      let response = await User.setPassword({old_password: this.old_password, new_password: this.new_password})
      this.loadingCambiar=false

      if(response.data.success) {
        console.log(response.data)
        this.$toast.success(this.$t('perfilcambiarclave.txt_se_cambio_su_contrasena'))
        this.formClave=false
        return
      }
      console.log(response.data)
      this.$toast.error(response.data.message)
    }
  }
}
</script>

<style scoped>
  .perfil_cambiar_clabe{ padding: 20px 0; }

  .clave__password{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 60px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .clave__buttons{
    display: flex;
    gap: 10px;
  }

  .password-button{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  @media only screen and (min-width: 425px){
    .clave__password,
    .input-container{ width: 300px; }
  }

  @media only screen and (min-width: 768px){
    .perfil_cambiar_clabe{ padding: 20px 0 20px 40px; }
    .clave__password,
    .input-container{ width: 100%; }
  }

  @media only screen and (min-width: 1024px){
    .clave__password,
    .input-container{ width: 300px; }
  }
</style>