<template>
  <div class="modal__bg">
    <div class="modal_create_tarjeta">
      <h3>{{titleTypeOrder()}}</h3>
      <p class="content">{{setMessage()}}</p>
      <div class="my_container">
        <div class="checkbox">
          <CheckboxComponent
              :id="'checkbox_confirm_order'"
              v-model="success">
            <p>{{ $t('modal.txt_he_leido_y_aceptop_los') }} <a href="https://quickeat.s3-us-west-2.amazonaws.com/Configuracion/terminosQE.pdf" target="_blank" class="enlace">{{ $t('modal.txt_terminos_y_condiciones') }}</a> {{ $t('modal.txt_y') }} <a href="https://quickeat.s3-us-west-2.amazonaws.com/Configuracion/politicasQE.pdf" target="_blank" class="enlace">{{ $t('modal.txt_politicas_y_tratamiento_de_datos_personales') }}</a></p>
          </CheckboxComponent>
          <CheckboxComponent
              v-if="businessInfo.business_permissions.includes('8')"
              :id="'checkbox_confirm_notification'"
              v-model="has_whatsapp_order_status">
            <p>{{ $t('modal.txt_quiero_recibir_notificaciones') }}</p>
          </CheckboxComponent>
        </div>
        <div class="buttons">
          <ButtonTexto
              type="button"
              @click="$emit('cancel')"
          >
            {{ $t('buttons.txt_cancelar') }}
          </ButtonTexto>
          <ButtonPrimary
              type="button"
              :loading="loading"
              @click="confirmSuccess"
          >
            {{ $t('buttons.txt_aceptar') }}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import CheckboxComponent from "@/components/new/inputs/CheckboxComponent";
import ButtonTexto from "../new/buttons/ButtonTexto.vue";
import UserServices from "@/services/user-services";
export default {
  name:"confirm-pay",

  emits: ['successNiubiz', 'termsAccepted', 'cancel'],
  props:{
    payload:Object,
    tipoOrden:Number,
    amount:Number,
    is_niubiz:Boolean
  },
  components:{
    CheckboxComponent,
    ButtonPrimary,
    ButtonTexto
  },
  mounted() {
    this.has_whatsapp_order_status = this.user.has_whatsapp_order_status
    this.first_load = false

  },
  data(){
    return{
      showModalPagarTarjeta:false,
      success:false,
      has_whatsapp_order_status: false,
      loading:false,
      first_load: true,
    }
  },
  methods:{
    ...mapMutations(['setUser']),
    titleTypeOrder(){
      let result='';
      switch(this.tipoOrden){
        case 2:
          result=this.$t('cobertura.txt_recojo_en_tienda');
          break;
        case 1:
          result=this.$t('cobertura.txt_reserva');
          break;
        case 3:
          result= this.$t('cobertura.txt_delivery');
          break;
      }
      return result;
    },
    setMessage(){
      let result='';
      switch(this.tipoOrden){
        case 2:
          result= this.$t('modal.txt_si_tuvieras_inconvenientes_camino');
          break;
        case 1:
          result= this.$t('modal.txt_si_tuvieras_inconvenientes_camino_reprogramar');
          break;
        case 3:
          result= this.$t('modal.txt_deseas_confirmar_tu_compra', { direction: this.orderDirection.direction });
          break;
      }
      return result;
    },
    async confirmSuccess(){
      if (!this.success) {
        this.$toast.error(this.$t('toast.txt_debe_aceptar_los_terminos'));
        return
      }
      if (this.is_niubiz) {
        this.$emit('successNiubiz')
        return
      }
      this.$emit('termsAccepted')
    }
  },
  watch: {
    async has_whatsapp_order_status() {
      if (this.first_load) {
        return
      }
      this.loading = true
      let response = await UserServices.updateMarketingPreferences({
        is_subscribed_mkt: this.user.is_subscribed_mkt,
        has_whatsapp_order_status: this.has_whatsapp_order_status
      })
      if (response.data.success) {
        this.setUser(response.data.data)
      } else {
        this.$toast.error(response.data.message)
      }
      this.loading = false
    }
  },
  computed: {
    ...mapState(["orderDirection", 'businessInfo', 'user'])
  }
}
</script>

<style scoped>
.modal_create_tarjeta{
  width: clamp(200px, 100%, 600px);
  height: max-content;
  background-color: var(--text-white);
  border-radius: 16px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h3{ margin-inline: auto; }

.content { font-weight: var(--regular); }

.checkbox{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.checkbox p{ font-size: 14px; }

.enlace{
  font-size: 14px;
  color: var(--primary);
  text-decoration: underline;
}

.buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media only screen and (min-width: 768px){
  .modal_create_tarjeta { padding: 30px; }
  .checkbox{ margin-bottom: 20px; }
  .checkbox p,
  .enlace{ font-size: 16px; }
}
</style>