<template>
  <section class="plato">
    <div class="plato__image" :style="backgroundPlaceholderItem(product)" >
    </div>
    <div class="plato__information">
      <div class="plato__text">
        <h3>{{product.name}}</h3>
        <p>{{product.description}}</p>
      </div>
      <div class="plato__prices-btn">
        <div class="plato__prices">
          <p class="discount" v-if="product.has_discount">S/.{{product.promo_price}}</p>
          <p class="anterior" v-if="product.has_discount">S/.{{product.price.toFixed(2)}}</p>
          <p v-else>S/.{{product.price.toFixed(2)}}</p>
        </div>
        <ButtonPrimary>{{ $t('buttons.txt_comprar') }}</ButtonPrimary>
      </div>
    </div>
    <div class="plato__discount" v-if="product.has_discount">
      <p>{{parseFloat((parseFloat(product.promo_price) - parseFloat(product.price))/(parseFloat(product.price))*100).toFixed(0)}}%</p>
    </div>
  </section>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  name: 'ProductoCarta',
  components: {ButtonPrimary},
  props: ['product'],
  methods: {

    backgroundPlaceholderItem() {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.product.img)
        if (!this.product.is_available) {
          return {background:`url(${this.product.img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${this.product.img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!this.product.is_available) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },
  }
}
</script>

<style scoped>
.plato{
  position: relative;
  display: flex;
  gap: 10px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  background: #EBE8D9;
  cursor: pointer;
  height: 150px;
  padding: 10px;
  transition: all .5s ease-in-out;
}

.plato__image{
  min-width: 120px;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  display: block;
}

.plato__information{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: -moz-max-content;
}

.plato__information p{
  font-size: 14px;
}

.plato__text{
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  height: 100px;
}

.plato__text:after{
  content: '';
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(235,232,217,1) 0%, rgba(235,232,217,0) 0%, rgba(235,232,217,1) 100%);
  opacity: 0;
  transition: all .5s ease-in-out;
}

.plato__text h3{
  font-size: 18px;
}

.plato__prices-btn{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: auto;
}

.plato__prices-btn button{ display: none; }

.plato__prices{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.plato__prices p{
  color: var(--text-black);
  font-size: 20px;
  font-weight: var(--medium);
  text-decoration: none;
}

.plato__prices .anterior{
  color: #858585;
  font-size: 16px;
  margin: 0;
  text-decoration: line-through;
}

.plato__prices .discount{
  color: #C40E0E;
  font-size: 20px;
  font-weight: var(--medium);
  text-decoration: none;
}

.plato__discount{
  position: absolute;
  top: 5px;
  left: 0;
  padding: 5px 10px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--primary-700);
}

.plato__discount p{
  color: var(--text-black);
  font-size: 18px;
  font-weight: var(--semibold);
}

@media only screen and (min-width: 360px){
  .plato__prices{
    flex-direction: row;
    gap: 20px;
  }
}

@media only screen and (min-width: 768px){
  .plato{
    max-width: 500px;
    height: 200px;
    padding: 0;
  }

  .plato__information{
    padding: 10px 10px 10px 0;
  }

  .plato__image{
    min-width: 200px;
    border-radius: 0;
  }

  .plato__text{
    height: 130px;
  }

  .plato__prices{
    margin-left: 0;
  }

  .plato:hover{
    background: #DED9C0;
  }

  .plato:hover .plato__text:after {
    background-image: linear-gradient(180deg, rgba(222,217,192,.5) 0%, rgba(222,217,192,0) 0%, rgba(222,217,192,1) 100%);
    opacity: 1;
  }
  .plato:not(:hover) .plato__text:after{
    background-image: linear-gradient(180deg, rgba(222,217,192,.5) 0%, rgba(222,217,192,0) 0%, rgba(222,217,192,1) 100%);
    opacity: 0;
  }
}
</style>