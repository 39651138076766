<template>
  <div class="base_component"
       v-if="status !== null && status !== undefined"
       :class="status==='caution'? 'caution__badge': 'danger__badge'"
  >
    <slot></slot>
  </div>
  <div class="base_component" v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  components: {

  },
  props: ['status'],
};
</script>

<style scoped>
.base_component {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 5px 10px;
  background-color: #e5f4ea;
  color: #0b994d;
  border: 1px solid #0b994d;
  border-radius: 16px;
  font-size: 14px;
  font-family: Poppins-regular;
  transition: background-color .3s linear;
}

.caution__badge {
  background-color: var(--text-black);
  border: 1px solid;
  color: var(--primary-700);
}

.danger__badge {
  background-color: #feebee;
  color: #b3251e;
  border: 1px solid #b3251e;
  padding: 5px 10px;
  border-radius: 16px;
}

</style>