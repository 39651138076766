<template>
  <button :disabled="disabled || loading">
    <SpinnerComponent
        :spinner-color="'var(--primary)'"
        :type="'small'"
        v-if="loading"
    >
    </SpinnerComponent>
    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  name: 'ButtonTexto',
  components: {SpinnerComponent},
  props: ['disabled', 'loading'],

};
</script>

<style scoped>
  button{
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--complementary);
  }

  button:hover{
    background-color: var(--complementary-50);
  }

  button:active{
    background-color: var(--complementary-100);
  }

  button[disabled]{
    background-color: transparent;
    border: transparent;
    color: var(--complementary-300);
    cursor: not-allowed;
  }
</style>