<template>
  <div class="input-container">
    <label class="input-label"
           :style="{background : `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`}" :class="{ 'activated': modelValue.length > 0 }">
      {{ placeholder }}
    </label>
    <input :value="modelValue"
           class="input__class"
           :placeholder="placeholder"
           @input="onChanged"
           :disabled="disabled"
           :required="required"
           :type="visible_password ? 'text' : 'password'"
    />
    <img
        @click="visible_password = !visible_password"
        v-if="visible_password"
        style="padding-left: 10px; cursor: pointer; position: absolute; right: 10px; width: 35px"
        src="@/assets/icons/new/action/visibility.svg" alt="ojo"
    >
    <img
        @click="visible_password = !visible_password"
        v-if="!visible_password"
        style="padding-left: 10px; cursor: pointer; position: absolute; right: 10px; width: 35px"
        src="@/assets/icons/new/action/visibility-off.svg" alt="ojo_cerrado"
    >
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "PasswordComponent",

  emits: ['update:modelValue', 'enterPressed'],

  data() {
    return {
      visible_password: false,
    }
  },

  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bg_color: {
      type: String,
      default: 'white'
    }

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.currentTarget.value);
    }

    return {
      onChanged
    }
  }
})
</script>

<style scoped>
.input-container {
  display: flex;
  align-items: center;
}
</style>