<template>
  <div class="container_perfil body_perfil">
    <div class="header_perfil">
      <div class="perfil__container">
        <p class="back" @click="$router.push('/')">
          <svg class="icon-close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.82505 13L12.725 17.9C12.925 18.1 13.0209 18.3334 13.0125 18.6C13.0042 18.8667 12.9 19.1 12.7 19.3C12.5 19.4834 12.2667 19.5792 12 19.5875C11.7334 19.5959 11.5 19.5 11.3 19.3L4.70005 12.7C4.60005 12.6 4.52922 12.4917 4.48755 12.375C4.44588 12.2584 4.42505 12.1334 4.42505 12C4.42505 11.8667 4.44588 11.7417 4.48755 11.625C4.52922 11.5084 4.60005 11.4 4.70005 11.3L11.3 4.70005C11.4834 4.51672 11.7125 4.42505 11.9875 4.42505C12.2625 4.42505 12.5 4.51672 12.7 4.70005C12.9 4.90005 13 5.13755 13 5.41255C13 5.68755 12.9 5.92505 12.7 6.12505L7.82505 11H19C19.2834 11 19.5209 11.0959 19.7125 11.2875C19.9042 11.4792 20 11.7167 20 12C20 12.2834 19.9042 12.5209 19.7125 12.7125C19.5209 12.9042 19.2834 13 19 13H7.82505Z" fill="currentColor"/>
          </svg>
          {{ $t("cobertura.txt_volver_al_inicio")}}
        </p>
        <p class="welcome"><strong>{{titleView()}}</strong></p>
        <div
            class="foto_perfil"
            :style="{'background-image':`url(${imagenPerfil()})`}"
        ></div>
      </div>
    </div>
    <div class="contenido">
      <div class="contenido__container">
        <div class="izquierda">
          <p class="izquierda__user-name">{{ $t('perfil.txt_hola') }} {{user.first_name}}!</p>
          <div class="izquierda__labels">
            <label class="izquierda__label"
                   :class="$route.name==='perfil.cuenta'?'tab_active':''"
                   @click="$router.push({name:'perfil.cuenta'})"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 12C10.9 12 9.95833 11.6083 9.175 10.825C8.39167 10.0417 8 9.1 8 8C8 6.9 8.39167 5.95833 9.175 5.175C9.95833 4.39167 10.9 4 12 4C13.1 4 14.0417 4.39167 14.825 5.175C15.6083 5.95833 16 6.9 16 8C16 9.1 15.6083 10.0417 14.825 10.825C14.0417 11.6083 13.1 12 12 12ZM4 18V17.2C4 16.6333 4.14583 16.1125 4.4375 15.6375C4.72917 15.1625 5.11667 14.8 5.6 14.55C6.63333 14.0333 7.68333 13.6458 8.75 13.3875C9.81667 13.1292 10.9 13 12 13C13.1 13 14.1833 13.1292 15.25 13.3875C16.3167 13.6458 17.3667 14.0333 18.4 14.55C18.8833 14.8 19.2708 15.1625 19.5625 15.6375C19.8542 16.1125 20 16.6333 20 17.2V18C20 18.55 19.8042 19.0208 19.4125 19.4125C19.0208 19.8042 18.55 20 18 20H6C5.45 20 4.97917 19.8042 4.5875 19.4125C4.19583 19.0208 4 18.55 4 18ZM6 18H18V17.2C18 17.0167 17.9542 16.85 17.8625 16.7C17.7708 16.55 17.65 16.4333 17.5 16.35C16.6 15.9 15.6917 15.5625 14.775 15.3375C13.8583 15.1125 12.9333 15 12 15C11.0667 15 10.1417 15.1125 9.225 15.3375C8.30833 15.5625 7.4 15.9 6.5 16.35C6.35 16.4333 6.22917 16.55 6.1375 16.7C6.04583 16.85 6 17.0167 6 17.2V18ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8042 9.02083 14 8.55 14 8C14 7.45 13.8042 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9792 6.19583 10.5875 6.5875C10.1958 6.97917 10 7.45 10 8C10 8.55 10.1958 9.02083 10.5875 9.4125C10.9792 9.80417 11.45 10 12 10Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_mi_perfil') }}
            </label>
            <label class="izquierda__label"
                   :class="$route.name==='perfil.clave'?'tab_active':''"
                   @click="$router.push({name:'perfil.clave'})"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_contrasena') }}
            </label>
            <label class="izquierda__label"
                   :class="$route.name==='direcciones.solo'?'tab_active':''"
                   @click="$router.push({name:'direcciones.solo'})"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19.35C14.0333 17.4833 15.5417 15.7875 16.525 14.2625C17.5083 12.7375 18 11.3833 18 10.2C18 8.38333 17.4208 6.89583 16.2625 5.7375C15.1042 4.57917 13.6833 4 12 4C10.3167 4 8.89583 4.57917 7.7375 5.7375C6.57917 6.89583 6 8.38333 6 10.2C6 11.3833 6.49167 12.7375 7.475 14.2625C8.45833 15.7875 9.96667 17.4833 12 19.35ZM12 21.325C11.7667 21.325 11.5333 21.2833 11.3 21.2C11.0667 21.1167 10.8583 20.9917 10.675 20.825C9.59167 19.825 8.63333 18.85 7.8 17.9C6.96667 16.95 6.27083 16.0292 5.7125 15.1375C5.15417 14.2458 4.72917 13.3875 4.4375 12.5625C4.14583 11.7375 4 10.95 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 10.95 19.8542 11.7375 19.5625 12.5625C19.2708 13.3875 18.8458 14.2458 18.2875 15.1375C17.7292 16.0292 17.0333 16.95 16.2 17.9C15.3667 18.85 14.4083 19.825 13.325 20.825C13.1417 20.9917 12.9333 21.1167 12.7 21.2C12.4667 21.2833 12.2333 21.325 12 21.325ZM12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_direcciones') }}
            </label>
            <label class="izquierda__label"
                   :class="$route.name==='pedidos'?'tab_active':''"
                   @click="$router.push({name:'pedidos'})"
            >
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 22.3428C5.45 22.3428 4.97917 22.1469 4.5875 21.7553C4.19583 21.3636 4 20.8928 4 20.3428V8.34277C4 7.79277 4.19583 7.32194 4.5875 6.93027C4.97917 6.53861 5.45 6.34277 6 6.34277H8C8 5.24277 8.39167 4.30111 9.175 3.51777C9.95833 2.73444 10.9 2.34277 12 2.34277C13.1 2.34277 14.0417 2.73444 14.825 3.51777C15.6083 4.30111 16 5.24277 16 6.34277H18C18.55 6.34277 19.0208 6.53861 19.4125 6.93027C19.8042 7.32194 20 7.79277 20 8.34277V20.3428C20 20.8928 19.8042 21.3636 19.4125 21.7553C19.0208 22.1469 18.55 22.3428 18 22.3428H6ZM6 20.3428H18V8.34277H16V10.3428C16 10.6261 15.9042 10.8636 15.7125 11.0553C15.5208 11.2469 15.2833 11.3428 15 11.3428C14.7167 11.3428 14.4792 11.2469 14.2875 11.0553C14.0958 10.8636 14 10.6261 14 10.3428V8.34277H10V10.3428C10 10.6261 9.90417 10.8636 9.7125 11.0553C9.52083 11.2469 9.28333 11.3428 9 11.3428C8.71667 11.3428 8.47917 11.2469 8.2875 11.0553C8.09583 10.8636 8 10.6261 8 10.3428V8.34277H6V20.3428ZM10 6.34277H14C14 5.79277 13.8042 5.32194 13.4125 4.93027C13.0208 4.53861 12.55 4.34277 12 4.34277C11.45 4.34277 10.9792 4.53861 10.5875 4.93027C10.1958 5.32194 10 5.79277 10 6.34277Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_mis_pedidos') }}
            </label>
            <!--            <label class="izquierda__label"-->
            <!--                   :class="$route.name==='misreservas'?'tab_active':''"-->
            <!--                   @click="$router.push({name:'misreservas'})"-->
            <!--            >-->
            <!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
            <!--                <path d="M10.95 18.35L7.4 14.8L8.85 13.35L10.95 15.45L15.15 11.25L16.6 12.7L10.95 18.35ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z" fill="currentColor"/>-->
            <!--              </svg>-->
            <!--              Mis reservas-->
            <!--            </label>-->
            <label class="izquierda__label"
                   :class="$route.name==='perfil.beneficios'?'tab_active':''"
                   @click="$router.push({name:'perfil.beneficios'})"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 19V15.9C10.1833 15.7167 9.45417 15.3708 8.8125 14.8625C8.17083 14.3542 7.7 13.7167 7.4 12.95C6.15 12.8 5.10417 12.2542 4.2625 11.3125C3.42083 10.3708 3 9.26667 3 8V7C3 6.45 3.19583 5.97917 3.5875 5.5875C3.97917 5.19583 4.45 5 5 5H7C7 4.45 7.19583 3.97917 7.5875 3.5875C7.97917 3.19583 8.45 3 9 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5H19C19.55 5 20.0208 5.19583 20.4125 5.5875C20.8042 5.97917 21 6.45 21 7V8C21 9.26667 20.5792 10.3708 19.7375 11.3125C18.8958 12.2542 17.85 12.8 16.6 12.95C16.3 13.7167 15.8292 14.3542 15.1875 14.8625C14.5458 15.3708 13.8167 15.7167 13 15.9V19H16C16.2833 19 16.5208 19.0958 16.7125 19.2875C16.9042 19.4792 17 19.7167 17 20C17 20.2833 16.9042 20.5208 16.7125 20.7125C16.5208 20.9042 16.2833 21 16 21H8C7.71667 21 7.47917 20.9042 7.2875 20.7125C7.09583 20.5208 7 20.2833 7 20C7 19.7167 7.09583 19.4792 7.2875 19.2875C7.47917 19.0958 7.71667 19 8 19H11ZM7 10.8V7H5V8C5 8.63333 5.18333 9.20417 5.55 9.7125C5.91667 10.2208 6.4 10.5833 7 10.8ZM12 14C12.8333 14 13.5417 13.7083 14.125 13.125C14.7083 12.5417 15 11.8333 15 11V5H9V11C9 11.8333 9.29167 12.5417 9.875 13.125C10.4583 13.7083 11.1667 14 12 14ZM17 10.8C17.6 10.5833 18.0833 10.2208 18.45 9.7125C18.8167 9.20417 19 8.63333 19 8V7H17V10.8Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_beneficios') }}
            </label>
            <label class="izquierda__label"
                   :class="$route.name==='perfil.notificaciones'?'tab_active':''"
                   @click="$router.push({name:'perfil.notificaciones'})"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 19C4.71667 19 4.47917 18.9042 4.2875 18.7125C4.09583 18.5208 4 18.2833 4 18C4 17.7167 4.09583 17.4792 4.2875 17.2875C4.47917 17.0958 4.71667 17 5 17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H19C19.2833 17 19.5208 17.0958 19.7125 17.2875C19.9042 17.4792 20 17.7167 20 18C20 18.2833 19.9042 18.5208 19.7125 18.7125C19.5208 18.9042 19.2833 19 19 19H5ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z" fill="CurrentColor"/>
              </svg>
              {{ $t('perfil.txt_gestion_de_notificaciones') }}
            </label>
            <label class="izquierda__label"
                   @click="showModalLogout=true"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 12V4C11 3.71667 11.0958 3.47917 11.2875 3.2875C11.4792 3.09583 11.7167 3 12 3C12.2833 3 12.5208 3.09583 12.7125 3.2875C12.9042 3.47917 13 3.71667 13 4V12C13 12.2833 12.9042 12.5208 12.7125 12.7125C12.5208 12.9042 12.2833 13 12 13C11.7167 13 11.4792 12.9042 11.2875 12.7125C11.0958 12.5208 11 12.2833 11 12ZM12 21C10.7667 21 9.60417 20.7625 8.5125 20.2875C7.42083 19.8125 6.46667 19.1667 5.65 18.35C4.83333 17.5333 4.1875 16.5792 3.7125 15.4875C3.2375 14.3958 3 13.2333 3 12C3 10.85 3.2125 9.74583 3.6375 8.6875C4.0625 7.62917 4.65833 6.68333 5.425 5.85C5.60833 5.65 5.84167 5.55 6.125 5.55C6.40833 5.55 6.65 5.65 6.85 5.85C7.03333 6.03333 7.11667 6.2625 7.1 6.5375C7.08333 6.8125 6.98333 7.05833 6.8 7.275C6.23333 7.90833 5.79167 8.62917 5.475 9.4375C5.15833 10.2458 5 11.1 5 12C5 13.9333 5.68333 15.5833 7.05 16.95C8.41667 18.3167 10.0667 19 12 19C13.95 19 15.6042 18.3167 16.9625 16.95C18.3208 15.5833 19 13.9333 19 12C19 11.1 18.8417 10.2375 18.525 9.4125C18.2083 8.5875 17.7583 7.85833 17.175 7.225C16.9917 7.025 16.8958 6.79167 16.8875 6.525C16.8792 6.25833 16.9667 6.03333 17.15 5.85C17.35 5.65 17.5917 5.55 17.875 5.55C18.1583 5.55 18.3917 5.65 18.575 5.85C19.3583 6.68333 19.9583 7.62917 20.375 8.6875C20.7917 9.74583 21 10.85 21 12C21 13.2333 20.7625 14.3958 20.2875 15.4875C19.8125 16.5792 19.1708 17.5333 18.3625 18.35C17.5542 19.1667 16.6042 19.8125 15.5125 20.2875C14.4208 20.7625 13.25 21 12 21Z" fill="currentColor"/>
              </svg>
              {{ $t('perfil.txt_cerrar_sesion') }}
            </label>
          </div>
        </div>
        <div class="derecha">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <TwoButtonGenericModal
          v-if="showModalLogout"
          :title="this.$t('buttons.txt_confirmar')"
          :content="this.$t('barralateralizquierda.txt_deseas_cerrar_sesion')"
          :accept="this.$t('barralateralizquierda.txt_si_salir')"
          :cancel="this.$t('barralateralizquierda.txt_no_seguir_navegando')"
          @close_modal="showModalLogout=false"
          @accepted_modal="logout"
      >
      </TwoButtonGenericModal>
    </transition>
  </div>

</template>

<script>
import { mapState, mapMutations } from "vuex"
import User from "@/services/user-services"
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal.vue";
export default {
  emits: ['close'],

  components: {
    TwoButtonGenericModal
  },
  computed: {
    ...mapState(['user'])
  },
  data () {
    return {
      showModalLogout: false,
      showModalDeleteAccount: false,
    }
  },
  methods: {
    ...mapMutations(['setAuthenticatedFalse', 'clearProducts', 'setWebVersion']),
    titleView() {
      switch (this.$route.name) {
        case 'perfil.cuenta':
          return 'Mis datos'
        case 'perfil.clave':
          return 'Contraseña'
        case 'direcciones.solo':
          return 'Direcciones'
        case 'pedidos':
          return 'Mis pedidos'
        case 'misreservas':
          return 'Mis reservas'
        case 'perfil.beneficios':
          return 'Beneficios'
        case 'perfil.notificaciones':
          return 'Notificaciones'
      }
      return 'TBD'
    },
    imagenPerfil () {
      return process.env.VUE_APP_IMG_PLACEHOLDER

    },
    async logout () {
      this.showModalLogout = false
      this.setAuthenticatedFalse()
      this.clearProducts()
      await User.setAuthHeaders(null)
      localStorage.clear()
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
      this.$emit('close')
      await this.$router.push("/")
    },
  }
}
</script>
<style scoped>
.header_perfil{
  background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Donoso/ux_web_files/carta-background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 0px 200px rgba(245, 181, 33, 0.8) inset;
  height: auto;
}

.contenido__container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1400px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.perfil__container{
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
}

.back{
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-black);
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: .3s linear;
  margin-right: auto;
  font-family: Poppins-extrabold;
  scale: 1;
}

.back:hover{
  text-decoration: underline;
  scale: 1.1;
}

.welcome{
  align-self: center;
  color: var(--text-black);
  font-size: 36px;
  font-family: Poppins-bold;
}

.contenido__container{
  padding: 0 16px;
}

.izquierda{
  display: none;
  width: 25%;
  height: 100vh;
  padding: 20px 40px 20px 0px;
  box-shadow: 6px 0px 6px 1px #2929292E;
}

.izquierda__user-name{
  font-size: 28px;
  font-weight: var(--medium);
  text-align: center;
  margin-bottom: 20px;
  font-family: Poppins-semibold;
}

.izquierda__labels{
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.izquierda__label{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: transparent;
  color: var(--complementary);
  font-size: 16px;
  cursor: pointer;
  transition: background-color .3s linear;
  font-family: Poppins-semibold;
}

.izquierda__label:hover{
  background-color: var(--primary-50);
  color: var(--complementary);
}

.izquierda__label svg{
  min-width: 24px;
  min-height: 24px;
}

.tab_active{
  color: var(--text-black) !important;
  background-color: var(--primary-700) !important;
}

.derecha{
  width: 100%;
}

.perfil__container{
  padding: 20px 16px;
}

@media only screen and (min-width: 768px){
  .contenido__container{ padding: 0 32px;}
  .izquierda{
    display: block;
    width: 400px;
  }
  .derecha{
    width: 100%;
  }
}

@media only screen and (min-width: 1024px){
  .perfil__container{
    flex-direction:row;
    align-items: center;
    padding: 10px 32px
  }
  .welcome{
    font-size: 42px;
    margin-right: auto
  }
  .icon-close{ scale: 1.2;}
  .back{ font-size: 22px; }
}

@media only screen and (min-width: 1440px){
  .izquierda{ width: 25%;}
  .derecha{ width: 75%; }
}
</style>