<template>
  <div class="perfil_mis_beneficios">
    <p class="beneficios_description">{{ $t('perfil.txt_conoce_tus_puntos_generador_por') }}</p>
    <div class="circle-beneficios">
      <div class="imagen">
        <CirclePoints :points="points"></CirclePoints>
        <img src="../../assets/images-new/image-chip.svg" alt="Logo de DONOSO">
        <p>{{ $t('perfil.txt_puntos_actuales') }}: {{points}}</p>
        <p>{{ $t('perfil.txt_canjeados') }}: {{total_points-points}}</p>
      </div>
      <div class="body_mis_beneficios">
        <div class="header__beneficios">
          <label class="beneficios__activos"
                 :class="tabActive_beneficios==='beneficios'?'active':''"
                 for="radio_beneficios"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.25 21.4C13.8667 21.7833 13.3917 21.975 12.825 21.975C12.2583 21.975 11.7833 21.7833 11.4 21.4L2.6 12.6C2.41667 12.4167 2.27083 12.2 2.1625 11.95C2.05417 11.7 2 11.4333 2 11.15V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H11.15C11.4333 2 11.7 2.05417 11.95 2.1625C12.2 2.27083 12.4167 2.41667 12.6 2.6L21.4 11.425C21.7833 11.8083 21.975 12.2792 21.975 12.8375C21.975 13.3958 21.7833 13.8667 21.4 14.25L14.25 21.4ZM12.825 20L19.975 12.85L11.15 4H4V11.15L12.825 20ZM6.5 8C6.91667 8 7.27083 7.85417 7.5625 7.5625C7.85417 7.27083 8 6.91667 8 6.5C8 6.08333 7.85417 5.72917 7.5625 5.4375C7.27083 5.14583 6.91667 5 6.5 5C6.08333 5 5.72917 5.14583 5.4375 5.4375C5.14583 5.72917 5 6.08333 5 6.5C5 6.91667 5.14583 7.27083 5.4375 7.5625C5.72917 7.85417 6.08333 8 6.5 8Z" fill="currentColor"/>
            </svg>
            <input
                type="radio"
                name="type_beneficio"
                id="radio_beneficios"
                v-model="tabActive_beneficios"
                value="beneficios"
            >
            {{ $t('perfil.txt_cupones') }}
          </label>
          <label class="beneficios__desactivos"
                 :class="tabActive_beneficios==='historial'?'active':''"
                 for="radio_historial"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 21C9.9 21 8.04167 20.3625 6.425 19.0875C4.80833 17.8125 3.75833 16.1833 3.275 14.2C3.20833 13.95 3.25833 13.7208 3.425 13.5125C3.59167 13.3042 3.81667 13.1833 4.1 13.15C4.36667 13.1167 4.60833 13.1667 4.825 13.3C5.04167 13.4333 5.19167 13.6333 5.275 13.9C5.675 15.4 6.5 16.625 7.75 17.575C9 18.525 10.4167 19 12 19C13.95 19 15.6042 18.3208 16.9625 16.9625C18.3208 15.6042 19 13.95 19 12C19 10.05 18.3208 8.39583 16.9625 7.0375C15.6042 5.67917 13.95 5 12 5C10.85 5 9.775 5.26667 8.775 5.8C7.775 6.33333 6.93333 7.06667 6.25 8H8C8.28333 8 8.52083 8.09583 8.7125 8.2875C8.90417 8.47917 9 8.71667 9 9C9 9.28333 8.90417 9.52083 8.7125 9.7125C8.52083 9.90417 8.28333 10 8 10H4C3.71667 10 3.47917 9.90417 3.2875 9.7125C3.09583 9.52083 3 9.28333 3 9V5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4C4.28333 4 4.52083 4.09583 4.7125 4.2875C4.90417 4.47917 5 4.71667 5 5V6.35C5.85 5.28333 6.8875 4.45833 8.1125 3.875C9.3375 3.29167 10.6333 3 12 3C13.25 3 14.4208 3.2375 15.5125 3.7125C16.6042 4.1875 17.5542 4.82917 18.3625 5.6375C19.1708 6.44583 19.8125 7.39583 20.2875 8.4875C20.7625 9.57917 21 10.75 21 12C21 13.25 20.7625 14.4208 20.2875 15.5125C19.8125 16.6042 19.1708 17.5542 18.3625 18.3625C17.5542 19.1708 16.6042 19.8125 15.5125 20.2875C14.4208 20.7625 13.25 21 12 21ZM13 11.6L15.5 14.1C15.6833 14.2833 15.775 14.5167 15.775 14.8C15.775 15.0833 15.6833 15.3167 15.5 15.5C15.3167 15.6833 15.0833 15.775 14.8 15.775C14.5167 15.775 14.2833 15.6833 14.1 15.5L11.3 12.7C11.2 12.6 11.125 12.4875 11.075 12.3625C11.025 12.2375 11 12.1083 11 11.975V8C11 7.71667 11.0958 7.47917 11.2875 7.2875C11.4792 7.09583 11.7167 7 12 7C12.2833 7 12.5208 7.09583 12.7125 7.2875C12.9042 7.47917 13 7.71667 13 8V11.6Z" fill="currentColor"/>
            </svg>
            <input
                type="radio"
                name="type_beneficio"
                id="radio_historial"
                v-model="tabActive_beneficios"
                value="historial"
            >
            {{ $t('perfil.txt_historial') }}
          </label>
        </div>
        <div class="content_beneficios">
          <div class="content_effect">
            <TabBeneficios
                v-if="tabActive_beneficios==='beneficios'"
                :loadPointsCircle="loadPointsCircle"
                :puntos="points"
                :fromPaymentScreen="false"
            ></TabBeneficios>
          </div>
          <div class="content_effect">
            <TabHistorial v-if="tabActive_beneficios==='historial'"></TabHistorial>
          </div>
        </div>
        <div class="footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TabBeneficios from "./PerfilMisBeneficiosBeneficios"
import TabHistorial from "./PerfilMisBeneficiosHistorial"
import CuponServices from "@/services/cupon-services"
import CirclePoints from "@/components/CirclePoints"
export default {
  async created () {
    await this.loadPointsCircle()
  },
  components: {
    TabBeneficios, TabHistorial,
    CirclePoints
  },
  data () {
    return {
      tabActive_beneficios: 'beneficios',
      points: 0,
      total_points: 0,
    }
  },
  methods: {
    async loadPointsCircle () {
      let response = await CuponServices.obtenerInfoDelUsuario()
      if (response.data.success) {
        this.points = response.data.data.points;
        this.total_points = response.data.data.total_points
      }
    }
  }
}
</script>

<style scoped>
.perfil_mis_beneficios{ padding: 20px 0px; }

.circle-beneficios{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.imagen{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
}

.imagen img{
  position: absolute;
  display: block;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imagen p{
  font-size: 14px;
  font-weight: var(--regular);
}

.body_mis_beneficios{
  width: 100%;
  height: max-content;
  border-radius: 8px;
  border: 1px solid var(--complementary-400);
}

.header__beneficios{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.beneficios__activos,
.beneficios__desactivos{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  transition: border-bottom .3s linear;
  font-size: 18px;
  padding: 10px 0px;
  font-family: Poppins-regular;
}

.beneficios__activos input,
.beneficios__desactivos input{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.active{
  border-bottom: 3px solid var(--primary-700);
}

.beneficios_description{
  margin-bottom: 20px;
}

.beneficos_label{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  padding: 10px;
  cursor: pointer;
  color: var(--text-black);
  font-weight: var(--regular);
  border-bottom: 3px solid transparent;
  transition: border-bottom .3s linear;
}

@media only screen and (min-width: 600px){
  .body_mis_beneficios{ width: 550px; }
}

@media only screen and (min-width: 768px){
  .imagen{ width: 180px; }
  .imagen p{ font-size: 16px; }
  .perfil_mis_beneficios{ padding: 20px 0 20px 40px; }
  .body_mis_beneficios{ width: 100%; }
}

@media only screen and (min-width: 1200px){
  .circle-beneficios{
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .body_mis_beneficios{ width: 75%; }
}
</style>