<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 160px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .carta__card h3{
    color: var(--text-black);
    font-family: Poppins-bold;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 1.2;
  }

  .carta__nombre{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50%;
    padding: 8px;
    opacity: 1;
    transition: opacity .3s linear;
    background: linear-gradient(0deg, rgba(245,181,33,1) 0%, rgba(18,16,11,0) 100%);
  }

  .carta__nombre h3{
    text-align: left;
    color: var(--text-black);
    margin-top: auto;
  }

  .carta__hover{
    display: none;
  }

  @media only screen and (min-width: 475px){
    .carta__card h3{
      font-size: 16px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 768px){
    .carta__card{
      height: 250px;
    }

    .carta__card h3{
      font-size: 28px;
      text-align: center;
    }

    .carta__hover{
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transform: translateY(80%);
      width: 100%;
      height: 100%;
      opacity: 0;
      padding-inline: 10px;
      box-shadow: inset 0 0 400px #f5b521;
      transition: transform .3s linear
    }

    .carta__hover button{
      text-transform: uppercase;
    }

    .carta__card:hover{
      flex-direction: column-reverse;
    }

    .carta__card:hover .carta__nombre{
      top: -200%;
      opacity: 0;
    }

    .carta__card:hover .carta__hover{
      opacity: 1;
      transform: translateY(0)
    }

    .carta__card:not(:hover) .carta__hover {
      transform: translateY(100%);
      opacity: 1
    }
  }
</style>